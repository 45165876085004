import React from "react";
import TrackVisibility from "react-on-screen";
import Counter from "../elements/Counter";
import Pagetitle from "../elements/Pagetitle";
import Skill from "../elements/Skill";

const aboutContent = {
  name: "Luminous",
  avatarImage: "/images/lumi.jpg",
  content: "Olumide Samuel is a Frontend Engineer at Touch and Pay Technologies. With over 2 years of experience in building web applications using javascript and it’s libraries/frameworks to help business grow. ",
  content2: "He has experience working in a fast paced financial technology startup in Lagos, Nigeria. He built the front-end Agency Management Web application and Cowry Payments Mobile app using React JS and React Native with Typescript. ",
  content3: "OlumideSamuel in the past years has experience working with REST APIs, Postman, Material UI, Ant Design, Chakra UI, SASS, Styled Components, Tailwind, React Query, GraphQL, Node JS,  Redux, Git, Netlify, Azure, Google Cloud Platforms, ASP .Net core, MongoDB, and so on across several projects."
};

const progressData = [
  {
    id: 1,
    title: "Frontend Web Development",
    subtitle: "Javascript (ReactJS, VueJS)",
    percantage: 100,
    progressColor: "#FFD15C",
  },
  {
    id: 2,
    title: "Cross Platform Mobile Development",
    subtitle: "React-Native JS",
    percantage: 100,
    progressColor: "#FF4C60",
  },
  // {
  //   id: 3,
  //   title: "Photography",
  //   percantage: 70,
  //   progressColor: "#6C6CE5",
  // },
];

const counterData = [
  {
    id: 1,
    title: "Projects completed",
    count: 198,
    icon: "icon-fire",
  },
  {
    id: 2,
    title: "Cup of coffee",
    count: 5670,
    icon: "icon-cup",
  },
  {
    id: 3,
    title: "Satisfied clients",
    count: 427,
    icon: "icon-people",
  },
  {
    id: 4,
    title: "Nominees winner",
    count: 35,
    icon: "icon-badge",
  },
];

function About() {
  return (
    <section id="about">
      <div className="container">
        <Pagetitle title="About Me" />
        <div className="row">
          <div className="col-md-3">
            <div className="text-center text-md-left">
              <img src={aboutContent.avatarImage} className="avatar" alt={aboutContent.name} />
            </div>
            <div className="spacer d-md-none d-lg-none" data-height="30"></div>
          </div>

          <div className="col-md-9 triangle-left-md triangle-top-sm">
            <div className="rounded bg-white shadow-dark padding-30">
              <div className="row">
                <div className="col-md-12">
                  <p>{aboutContent.content}</p>
                  <p>{aboutContent.content2}</p>
                  <p>{aboutContent.content3}</p>
                  <div className="mt-3">
                    <a
                      href="https://drive.google.com/file/d/1cRDXkpkLRTnxo3W8KxGHHYt-F2pt9rru/view?usp=sharing"
                      className="btn btn-default"
                      target="_blank"
                    >
                      Download CV
                    </a>
                  </div>
                  <div
                    className="spacer d-md-none d-lg-none"
                    data-height="30"
                  ></div>
                </div>
                {/* <div className="col-md-6">
                  {progressData.map((progress) => (
                    <TrackVisibility
                      once
                      key={progress.id}
                      className="progress-wrapper"
                    >
                      <Skill progress={progress} />
                    </TrackVisibility>
                  ))}
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="spacer" data-height="70"></div>
        {/* <div className="row fix-spacing">
          {counterData.map((counter) => (
            <div key={counter.id} className="col-md-3 col-sm-6">
              <TrackVisibility once>
                <Counter counterItem={counter} />
              </TrackVisibility>
            </div>
          ))}
        </div> */}
      </div>
    </section>
  );
}

export default About;
